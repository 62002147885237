import "../styles/App.scss";
import "../components/articleBlock/articleBlock.scss";
import "../components/highlightsPoint/highlightsPoints.scss";
import "../components/recentTweets/recentTweets.scss";
import "../components/tables/tables.scss";
import "./post/blog-post.scss";
import { authGetRequest, authPostRequest } from "../utils/authGetRequest";
import "../components/predictiveTools/predictiveTools.scss";
import Utils from "../utils/Utils";
import { useContext, useReducer, useEffect, useState, useRef } from "react";
import { CookiesProvider } from "react-cookie";
import { AppContext, AppContextWrapper } from "../context/state";

import { getAppService } from "../utils/getAppService";
import { ApolloProvider } from "@apollo/client";
import client from "../utils/apolloClient";
import Script from "next/script";
import useUTMParams from "../hooks/useUTMParams";
import Head from "next/head";
import { useRouter } from "next/router";
import { destroyCookie } from "nookies";
import { BsAlarmFill } from "react-icons/bs";
import * as ga from "../lib/GoogleAnalytics";
import dynamic from "next/dynamic";

// const PlausibleProvider = dynamic(() => import("next-plausible"), { ssr: false });
import PlausibleProvider from "next-plausible";
import { toast } from "react-toastify";

const MyApp = ({ Component, pageProps, user, userDetail, token }) => {
  const context = useContext(AppContext);
  const [_user, _setUser] = useState(user);
  const foundNotificationsRef = useRef(false); // Add useRef for foundNotifications
  const intervalId = useRef(null);

  const router = useRouter();
  const utmParams = useUTMParams();
  const isTrader = Utils.isTrader(userDetail);
  const emailDomain = userDetail?.email.split("@")[1].split(".")[0];
  const reducer = (store, newStore) => {
    if (newStore === null) {
      localStorage.removeItem("store");
      return context;
    }
    return { ...store, ...newStore };
  };

  const getInitialStoreValue = () => {
    if (typeof window !== "undefined") {
      // Check if localStorage is available
      const storedValue = localStorage?.getItem("store");
      if (storedValue) {
        return JSON.parse(storedValue);
      }
    }
    return null; // Return a default value if localStorage is not available
  };

  const setModelSandboxFilterParams = (params) => {
    context.params = params;
  };

  const handleSummaryData = (thresholdValue, summaryData, overallSummaryData) => {
    context.thresholdValue = thresholdValue;
    context.summaryData = summaryData.filter(
      (option) => option.value_threshold === thresholdValue && option.bet_type !== "all"
    );
    context.overallSummaryData = overallSummaryData;
  };

  const [store, setStore] = useReducer(reducer, getInitialStoreValue() || context);

  // useEffect(() => {
  //   const version = localStorage.getItem("version");
  //   if (version !== process.env.NEXT_PROJECT_VERSION) {
  //     const isUserLoggedIn = user !== null;
  //     // localStorage.clear();
  //     destroyCookie({}, "access_token");
  //     destroyCookie({}, "token_access");
  //     destroyCookie({}, "name");
  //     localStorage.setItem("version", process.env.NEXT_PROJECT_VERSION);
  //     if (isUserLoggedIn) {
  //       router.push("/login#login");
  //     }
  //   }
  // }, []);

  // Commented out since user is passed to _user state from getInitialProps
  // useEffect(() => {
  //   _setUser(user ? user : null);
  // }, [user]);

  // Handle Route Changes and Popstate
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    window.addEventListener("popstate", () => router.push(window.location.href));

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      window.removeEventListener("popstate", () => router.push(window.location.href));
    };
  }, [router.events]);

  const fetchLiveQueries = () => {
    if (foundNotificationsRef.current) {
      clearInterval(intervalId.current);
      return;
    }
    authGetRequest(`/api/extras/shotquality_live_queries`, token)
      .then((response) => response.data)
      .then((response) => {
        if (response?.data) {
          authPostRequest(`/api/live-query/edges`, token, {
            edges: JSON.parse(response.data),
            league: "wnba",
          })
            .then((response) => response.data)
            .then((response) => {
              const leagues = ["nba", "wnba", "ncaa"];
              leagues.forEach((league) => {
                if (!response?.data?.[league]) {
                  return;
                }
                let edges = Object.keys(response?.data?.[league]).map((key) => response.data[league][key]);
                edges.sort((a, b) => b.count - a.count);
                let top2Edges = edges.slice(0, 2);
                top2Edges.forEach((game, index) => {
                  if (game?.count === 0) return;
                  foundNotificationsRef.current = true; // Update the ref
                  setTimeout(() => {
                    toast.error(<ToastMsg sentence={game.sentence} />, {
                      icon: false,
                      autoClose: 7000,
                      position: "bottom-right",
                      theme: "dark",
                      onClick: (event) => {
                        redirectToPage(
                          `/${league}/live-query-tool/live-notifications?qm=live&type=trader&org=${emailDomain}`
                        );
                      },
                      style: { cursor: "pointer" },
                    });
                  }, (index + 1) * 2500);
                });
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      })
      .catch(async (err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const isNotMobileScreen = window.innerWidth > 768;
    if (
      _user &&
      isTrader &&
      isNotMobileScreen &&
      !router.pathname.match(/\/login|\/game|\/signup|\/forgot-password|\/reset-password|\/live-query-tool/)
    ) {
      fetchLiveQueries();
      intervalId.current = setInterval(fetchLiveQueries, 20000);
      return () => clearInterval(intervalId.current);
    }
  }, [isTrader, _user]);
  const redirectToPage = (link) => {
    router.push(link);
  };
  const ToastMsg = ({ closeToast, toastProps, sentence = "" }) => (
    <div className="text-black">
      <table>
        <tr>
          <td>
            {/* <BsAlarmFill style={{ color: "#07bc0c", fontSize: "1.4rem", marginRight: ".8rem" }} /> */}
            <BsAlarmFill style={{ color: "red", fontSize: "1.5rem", marginRight: ".8rem" }} />
          </td>
          <td className="text-white" style={{ fontSize: "1.2rem" }}>
            <span className="text-underline">Live Edge Update:</span> <strong> {sentence} </strong>
          </td>
        </tr>
      </table>
    </div>
  );
  return (
    <>
      {/* <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_GOOGLE_ANALYTICS}`}
      /> */}
      <Script strategy="afterInteractive" id="google-analytics-script">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
        `}
      </Script>
      {!isTrader && _user && (
        <Script
          strategy="afterInteractive"
          src="https://backend.alia-cloudflare.com/public/embed.js?shop=shot-quality-official-store.myshopify.com"
          async
        />
      )}
      <PlausibleProvider domain="shotqualitybets.com">
        <AppContextWrapper
          state={{
            store,
            setStore,
            setModelSandboxFilterParams,
            context,
            handleSummaryData,
            user: _user,
            userDetail: userDetail,
          }}
        >
          <Head>
            <meta charSet="utf-8" />
            <meta name="google-site-verification" content="EMglQMoVUkn-CAHBbODcnii7_Vw0MBVOICnB56wCji4" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <title>ShotQualityBets</title>
          </Head>
          <CookiesProvider>
            <ApolloProvider client={client}>
              <Component {...pageProps} />
            </ApolloProvider>
          </CookiesProvider>
        </AppContextWrapper>
      </PlausibleProvider>
    </>
  );
};

MyApp.getInitialProps = async ({ ctx }) => {
  const appService = await getAppService(ctx);
  const user = appService.getUser();
  let userDetail = null;
  let token = null;
  if (user) {
    token = appService.getToken();
    userDetail = await appService.get("api/account");
  }
  return { user, userDetail, token };
};

export default MyApp;
